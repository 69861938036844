<template>
  <div class="top">
    <div class="modify-carousel-outside">
      <div class="modify-carousel">
        <!-- 轮播及左边小块 -->
        <el-container>
          <el-main>
            <el-carousel height="100%" class="lunbo">
              <el-carousel-item v-for="(item,i) in imagesbox" :key="i">
                <div id="tip">
                  <!-- <font
                    style="position:absolute;bottom:21px;left:15px;display:inline-block;letter-space:1px;"
                    color="#cccccc"
                  >{{ item.info }}</font> -->
                  <img :src="item.idView" class="image" @click="$router.push('/Resource_details?id='+item.info)"/>
                </div>
              </el-carousel-item>
            </el-carousel>
          </el-main>
          <el-aside width="1.72794rem">
            <div class="person">
              <div class="Statement source-intro-xjw">
                <span class="bold">“资源交易”</span>是专业内容知识服务智荟网的一个版块，是专注于学术.科研.前沿技术问答交易的平台，欢迎您的加入，共同开启更多精彩。
              </div>
              <el-button type="primary" size="mini" @click="download">
                上传
                <i class="el-icon-upload el-icon--right"></i>
              </el-button>
            </div>
          </el-aside>
        </el-container>
      </div>
    </div>
    <!-- 轮播下方内容部分 -->
    <div class="center">
      <!-- <el-row :gutter="10" class="theme">
        <el-col :xs="8" :sm="6" :md="4" :lg="18" :xl="1">
          <div class="grid-content bg-purple">最新文档</div>
        </el-col>
        <el-col :xs="4" :sm="6" :md="8" :lg="3" :xl="11">
          <div class="grid-content bg-purple-light">资源达人</div>
        </el-col>
      </el-row>-->
      <el-container>
        <el-main class="main">
          <div class="list">
            <div class="grid-content bg-purple xjw-title">最新文档</div>
            <Resources @init="init" />
          </div>
          <div class="theme1">
            <div class="theme_title">八大领域</div>
            <div class="theme_list">
              <div
                class="theme_list_item cuc"
                v-for="(item,i) of themeList"
                :key="i">
                <img :src="item.img" class="list_item_img" />
                <div class="list_item_right">
                  <div class="item_right_title">
                    <span @click="Jump(item.title)">{{item.title}}</span>
                    <div @click="tapDyZt(item)">订阅</div>
                  </div>
                  <div class="item_right_comment">{{item.content}}</div>
                </div>
              </div>
            </div>
          </div>

          <!-- <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
            <el-menu-item index="计算机科学">计算机科学</el-menu-item>
            <el-menu-item index="材料科学">材料科学</el-menu-item>
            <el-menu-item index="电工电子">电工电子</el-menu-item>
            <el-menu-item index="教育学">教育学</el-menu-item>
            <el-menu-item index="图书情报">图书情报</el-menu-item>
            <el-menu-item index="合成生物学">合成生物学</el-menu-item>
            <el-menu-item index="人工智能">人工智能</el-menu-item>
            <el-menu-item index="机械工程">机械工程</el-menu-item>
          </el-menu>-->

          <!-- <h2>更多资源</h2>
          <ul class="aside_al">
            <li
              class="plcont"
              v-for="(item, index) in morelist.slice(0, 10)"
              :key="index"
              @click="change(item)"
            >
              <img :src="item.img" class="ing" />
              <div class="title">{{ item.name }}</div>
              <div class="cawd">
                <span class="down">{{ item.Time }}</span>
                <span class="down"> 资源等级{{ item.level }}</span>
                <span class="down">{{ item.download }}次下载</span>
                <span class="down">{{ item.Price }}知识币</span>
                <span class="down">作者:{{ item.UserauthorName }}</span>
              </div>
            </li>
            <div class="block" style="    text-align: center;">
              <el-pagination
                layout="prev, pager, next"
                :total="totlePage"
                :page-size="pageSize"
                @current-change="currentPage"
              >
              </el-pagination>
            </div>
          </ul>-->
        </el-main>

        <el-aside width="1.72794rem">
          <div class="aside_t">
            <div class="grid-content bg-purple-light xjw-title">资源达人</div>
            <expert />
          </div>

          <div class="aside_rm" style="margin-top:40px;">
            <div class="header">
              热门文档排行
              <div class="btn_dy" @click="tapDyZy(hotlist)">订阅</div>
            </div>
            <ul class="rm_center">
              <li class="plcont"  v-for="(item, index) in hotlist" :key="index" @click="change(item)">
                <div class="index">{{ index + 1 }}</div>
                <div class="rm_center_right">
                  <div class="title">{{ item.name }}</div>
                  <div class="rm_li">
                    <span class="down">资源等级{{ item.level }}</span>
                    <span class="down">{{ item.download }}次下载</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </el-aside>
      </el-container>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import api from "@/dev/api.ts";
import { INSERT, QUERY, DELETE, QUERYED, UPDATES } from "@/services/dao.js";
import { integralAddOne } from "@/js/common";
import Resources from "@/components/Resources_list";
import expert from "@/components/Resource_expert";
import { getTimeFormat } from "@/js/common";
import { updateread } from "@/js/common/Resoures";
export default {
  name: "index",
  components: {
    Resources,
    expert
  },
  data() {
    return {
      activeIndex: "1",
      Collectionlist: [],
      hotlist: [],
      morelist: [],
      pageNum: 0,
      pageSize: 10, //原来是10
      totlePage: 0,
      searchWord: "",
      imagesbox: [
        { id: 0, idView: require("@/assets/timg (9).png") },
        { id: 1, idView: require("@/assets/timg (9).png") },
        { id: 2, idView: require("@/assets/timg (9).png") }
      ],
      transaction: {
        img: require("@/assets/timg (9).png"),
        name: "aaa",
        zhishi: "10",
        fb: "200"
      },

      themeList: [] //主题列表
    };
  },
  created() {
    this.init();
    this.hotinit();
    this.moreinit(this.pageSize, this.pageSize * this.pageNum);
    this.initCarousel(); // 轮播
    this.getThemeList();
  },
  methods: {
    Jump(name) {
      this.$router.push({
        path: "/Resource_theme",
        query: {
          name: name
        }
      });
    },
    //订阅资源
    async tapDyZy(list) {
      let userId = this.$store.state.userinfo.id;
      if (!userId) {
        this.$message({
          message: "请您先登录！",
          type: "warning",
          duration: "1000"
        });
        return;
      }
      for (let item of list) {
        let zyId = item.id;
        let res = await QUERYED(
          "post",
          "",
          `UserAttentionZy(where:{zyId:{_eq:${zyId}},userId:{_eq:${userId}},type:{_eq:"2"}}) {id}`
        ); 
        if(res.success&&res.data.UserAttentionZy.length<=0){
          let res = await INSERT(
            "post",
            "",
            `insert_UserAttentionZy(objects:{zyId:${zyId},userId:${userId},type:"2"}){affected_rows}`
          );
        }
      }
      this.$message({
        message: "订阅成功",
        type: "success",
        duration: "1000"
      });
    },
    //订阅主题
    async tapDyZt(item) {
      let userId = this.$store.state.userinfo.id;
      if (!userId) {
        this.$message({
          message: "请您先登录！",
          type: "warning",
          duration: "1000"
        });
        return;
      }
      let zyId = item.id;
      let res = await QUERYED(
        "post",
        "",
        `UserAttentionZy(where:{zyId:{_eq:${zyId}},userId:{_eq:${userId}},type:{_eq:"1"}}) {id}`
      );
      if (!res.success) {
        this.$message({
          message: "订阅失败，请重试",
          type: "warning",
          duration: "1000"
        });
        return;
      }
      if (res.data.UserAttentionZy.length > 0) {
        this.$message({
          message: "当前主题已订阅",
          type: "warning",
          duration: "1000"
        });
        return;
      }
      let UserAttentionZy = await INSERT(
        "post",
        "",
        `insert_UserAttentionZy(objects:{zyId:${zyId},userId:${userId},type:"1"}){affected_rows}`
      );
      if (UserAttentionZy.success) {
        this.$message({
          message: "主题订阅成功",
          type: "success",
          duration: "1000"
        });
      } else {
        this.$message({
          message: "订阅失败，请重试",
          type: "warning",
          duration: "1000"
        });
      }
    },

    async getThemeList() {
      let res = await QUERYED("post", "", "Theme{id content title img }");
      for (let item of res.data.Theme) {
        item.img = api.LoginURL + item.img;
      }
      this.themeList = res.data.Theme;
      console.log(this.themeList, 753951);
    },

    repot() {
      this.$router.push({ name: "Reexpert", params: { userId: 123 } });
    },
    handleSelect(key, keyPath) {
      this.searchWord = key;
      this.moreinit(this.pageSize, this.pageSize * this.pageNum);
    },
    async init() {
      let res = await QUERY(
        "post",
        "",
        "ZYDocument{level Price  Time   collection  download    id   img  name  score  user}"
      );
      this.Collectionlist = res.data.ZYDocument;
      for (var i = 0; i < this.Collectionlist.length; i++) {
        this.Collectionlist[i].img = this.api.LoginURL.concat(
          this.Collectionlist[i].img
        );
      }
    },
    currentPage(val) {
      this.pageNum = val - 1;
      this.moreinit(this.pageSize, this.pageSize * this.pageNum);
    },
    async hotinit() {
      let res = await QUERY(
        "post",
        "",
        "ZYHotDocument(limit:22,offset:0){download id  name  score  read level}"
      );
      this.hotlist = res.data.ZYHotDocument;
    },
    async moreinit(limit, offest) {
      let res = await QUERY(
        "post",
        "",
        "ZYMoreDocument(limit: " +
          limit +
          ", offset: " +
          offest +
          ', where: {classification: {_like: "%' +
          this.searchWord +
          '%"}}){  level  Price    Time   collection  download  read  id   img  name  score  user format classification UserauthorName} ZYMoreDocument_aggregate(where: {}){    aggregate {      count    }  }'
      );

      for (let i = 0; i < res.data.ZYMoreDocument.length; i++) {
        res.data.ZYMoreDocument[i].Time = getTimeFormat(
          Date.parse(res.data.ZYMoreDocument[i].Time)
        );

        res.data.ZYMoreDocument[i].img = this.api.LoginURL.concat(
          res.data.ZYMoreDocument[i].img
        );
      }
      this.totlePage = res.data.ZYMoreDocument_aggregate.aggregate.count;
      this.morelist = res.data.ZYMoreDocument;
      for (let i = 0; i < this.morelist.length; i++) {
        switch (this.morelist[i].format) {
          case "mp3":
            this.morelist[i].img = require("@/assets/img/MP3.png");
            break;
          case "mp4":
            this.morelist[i].img = require("@/assets/img/MP4.png");
            break;
          case "jpg":
            this.morelist[i].img = require("@/assets/icon/？@2x.png");
            break;
          case "jpeg":
            this.morelist[i].img = require("@/assets/icon/？@2x.png");
            break;
          case "png":
            this.morelist[i].img = require("@/assets/icon/？@2x.png");
            break;
        }
      }
      this.initiallist = this.morelist;
      console.log(this.initiallist);
    },
    change(item) {
      let user = this.$store.state.userinfo.id;
      updateread(item.id, item.read);
      if(user){
      integralAddOne(user); ///积分加1
      }
      this.$router.push({
        path: "/Resource_details",
        query: {
          id: item.id
        }
      });
    },
    download() {
      if (
        this.$store.state.userinfo.id < 0 ||
        this.$store.state.userinfo.id == "" ||
        this.$store.state.userinfo.id == null ||
        this.$store.state.userinfo.id == "undefined"
      ) {
        this.$message({
          message: "请您先登录！",
          type: "warning",
          duration: "1000"
        });
      } else {
        this.$router.push("mydownload");
      }
    },
    // 初始化轮播图
    async initCarousel() {
      this.imagesbox = [];
      let info = await QUERYED(
        "post",
        "",
        "TtCarousel(where: {type:{_eq:" +
          2 +
          "}}) {id img content} TtCarousel_aggregate(where:{type:{_eq:" +
          2 +
          "}}) {aggregate { count }} "
      );
      console.log("imagebox: ", info);
      // let count = info.data.TtCarousel_aggregate.aggregate.count;
      // this.total=count;
      for (let i = 0; i < info.data.TtCarousel.length; i++) {
        this.imagesbox.push({
          id: info.data.TtCarousel[i].id,
          idView: this.api.LoginURL.concat(info.data.TtCarousel[i].img),
          info: info.data.TtCarousel[i].content
        });
      }
    }
  }
};
</script>

<style scoped>
.el-main {
  padding: 0;
  background-color: #f7f7f7;
}
.el-button {
  line-height: 0 !important;
}
.list {
  background-color: #ffffff;
  /* height: 619px; */
}
.lunbo {
  width: 100%;
  height: 100%;
  float: left;
}
.lunbo img {
  width: 100%;
  height: 100%;
}
.person {
  background-color: #ffffff;
  float: left;
  width: 100%;
  height: 100%;
}
.Statement {
  letter-spacing: 1.5px;
  overflow: hidden;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: rgba(0, 0, 0, 1);
  /* margin: 20px; */
  padding: 20px 20px 40px 20px;
  background-color: #eeeeee;
}
.picing {
  width: 72px;
  height: 72px;
  border-radius: 100%;
  margin: 29px 5px 10px 56px;
  float: left;
}
.name {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(2, 2, 2, 1);
  line-height: 130px;
}
.zs {
  margin-left: 23px;
  margin-right: 23px;
}
.el-button {
  width: 60%;
  height: 28px;
  line-height: 28px;
  margin: 24px 33px 25px 45px;
  color: #ffffff;
}

.nav {
}
.el-menu--horizontal > .el-menu-item {
  width: 12.5%;
  background-color: #0e86f6;
  color: #ffffff;
}
.el-menu-demo {
  margin-top: 37px;
}
.plcont {
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  padding-bottom: 14px;
  padding-top: 14px;
  border-bottom: 1px solid #f7f7f7;
}
.plcont .ing {
  width: 34px;
  height: 50px;
  margin-right: 10px;
  float: left;
}
.plcont .title {
  font-size: 0.095588rem;
  /* width: 150px; */
  /* float: left; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(2, 2, 2, 1);
}
.cawd {
  margin-top: 10px;
  width: 100%;
  position: relative;
}
.cawd .down {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(83, 83, 83, 1);
  line-height: 23px;
  width: 15%;
  /* position: absolute; */
  float: left;
}
.aside_al {
  background-color: #ffffff;
}
.aside_rm {
  margin-top: 135px;
  background-color: #ffffff;
}
.aside_rm .header {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  padding: 10px;
  /* margin: 10px; */
  border-bottom: 1px solid #d8d7d7;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rm_center .plcont {
  margin-left: -10px;
}
.rm_center .plcont .index {
  float: left;
  padding: 10px;
  background-color: #f7f7f7;
  margin-right: 10px;
  margin-left: 10px;
}
.rm_li {
  margin-top: 5px;
}
.rm_li .down {
  margin-right: 20px;
}
.source-intro-xjw {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #000000;
}
.bold {
  font-weight: bold;
}
#tip {
  position: relative;
  width: 100%;
  height: 100%;
}

.rm_center {
  padding-inline-start: 0px;
  padding: 20px;
}

.rm_center .plcont[data-v-0be50d88] {
  margin-left: 0px;
}
.modify-carousel-outside {
  padding-bottom: 20px;
  padding-top: 20px;
  background-color: #ffffff;
}
.modify-carousel {
  width: 80%;
  margin: 0 auto;
}
.center {
  width: 80%;
  margin: 0 auto;
  padding-top: 29px;
}
.xjw-title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  padding: 10px;
  border-bottom: 1px solid #d8d7d7;
}
.theme1 {
  width: 100%;
  /* border-top: 1px solid #999;
  border-top: 1px solid #999; */
  background-color: #fff;
}
.theme_title {
  padding: 10px 20px;
  border-bottom: 1px solid #999;
  font-size: 18px;
}
.theme_list {
  padding: 0 20px;
}
.theme_list_item {
  padding: 10px 0;
  display: flex;
  border-bottom: 1px solid #999;
}
.list_item_img {
  width: 270px;
  height: 170px;
}
.list_item_right {
  flex: 1;
  margin-left: 10px;
  height: 170px;
}
.item_right_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item_right_title > span {
  font-size: 20px;
  color: #000;
  cursor: pointer;
}
.item_right_title > div,
.btn_dy {
  width: 80px;
  height: 35px;
  background-color: rgb(14, 134, 246);
  border-radius: 5px;
  text-align: center;
  line-height: 35px;
  color: #ffffff;
  font-size: 16px;
}
.item_right_title > div:hover,
.btn_dy:hover {
  opacity: 0.9;
  cursor: pointer;
}
.item_right_comment {
  width: 480px;
  height: 115px;
  margin-top: 10px;
  font-size: 14px;
  text-align: justify;
  color: #333;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  overflow: hidden;
}
.cuc {
  cursor: pointer;
}
</style>
