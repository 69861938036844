<template>
  <ul class="aside_t_b">
    <li
      class="plcont"
      v-for="(item, index) in Collectionlist.slice(0, 8)"
      :key="index"
      @click="change(item)"
    >
      <img :src="item.img" class="ing" />
      <div class="title">{{ item.name }}</div>
      <br />
      <div>
        <span class="down">{{ item.download }}下载</span>
        <span class="down">{{ item.level }}级</span>
      </div>
    </li>
  </ul>
</template>

<script>
import gql from "graphql-tag";
import { INSERT, QUERY, DELETE } from "@/services/dao.js";
import { integralAddOne } from "@/js/common";
import { updateread } from "@/js/common/Resoures";
export default {
  data() {
    return {
      Collectionlist: []
    };
  },
  created() {
    this.init();
    console.log(this.Collectionlist);
  },
  methods: {
    async init() {
      let res = await QUERY(
        "post",
        "",
        "ZYDocument{ level   Price  read  Time   collection  download    id   img format name  score  user }"
      );

      for (var i = 0; i < res.data.ZYDocument.length; i++) {
        if (
          res.data.ZYDocument[i].format == "pptx" ||
          res.data.ZYDocument[i].format == "pdf" ||
          res.data.ZYDocument[i].format == "doc" ||
          res.data.ZYDocument[i].format == "docx" ||
          res.data.ZYDocument[i].format == "xls" ||
          res.data.ZYDocument[i].format == "xlsx"
        ) {
          this.Collectionlist.push({
            user: res.data.ZYDocument[i].user,
            score: res.data.ZYDocument[i].score,
            read: res.data.ZYDocument[i].read,
            level: res.data.ZYDocument[i].level,
            name: res.data.ZYDocument[i].name,
            download: res.data.ZYDocument[i].download,
            collection: res.data.ZYDocument[i].collection,
            id: res.data.ZYDocument[i].id,
            img: this.api.LoginURL.concat(res.data.ZYDocument[i].img)
          });
        }
      }
    },
    change(item) {
      let user = this.$store.state.userinfo.id;
      console.log(item);
      updateread(item.id, item.read);
      if (user) {
        integralAddOne(user);
      }
      this.$router.push({
        path: "/Resource_details",
        query: {
          id: item.id
        }
      });
    }
  }
};
</script>
<style scoped>
.plcont {
  width: 25%;
  float: left;
  margin-top: 20px;
  margin-bottom: 40px;
}
.ing {
  width: 90%;
  height: 230px;
  margin-left: 5%;
  margin-right: 5%;
  cursor: pointer;
}
.title {
  text-align: center;
  margin-left: 20px;
  width: 150px; /* 要显示文字的宽度 */
  float: left; /* 左对齐，不设置的话只在IE下好用 */
  overflow: hidden; /* 超出的部分隐藏起来 */
  white-space: nowrap; /* 不显示的地方用省略号...代替 */
  text-overflow: ellipsis; /* 支持 IE */
  -o-text-overflow: ellipsis; /* 支持 Opera */
  cursor: pointer;
}
ul {
  overflow: hidden;
  padding: 0;
}
.score {
  margin-left: 40px;
}
.down {
  float: right;
  margin-right: 40px;
}
</style>
