<template>
  <div>
    <ul class="aside_t_b">
      <li
        class="plcont"
        v-for="(item, index) in expertlist.slice(0, 5)"
        :key="index"
        @click="repot(item)"
      >
        <img :src="item.circleurl" @click="repot(item)" />
        <div class="centr">
          <span class="title">{{ item.username }}</span
          ><br />
          <span class="down">上传{{ item.works }}个</span>
        </div>

        <el-button
          size="mini"
          type="primary"
          @click.stop="subscribe(item, index)"
          v-show="item.show"
          >关注</el-button
        >
        <el-button
          size="mini"
          type="warning"
          @click.stop="subscribe_war(item, index)"
          v-show="item.show_war"
          >取关</el-button
        >
      </li>
    </ul>
  </div>
</template>

<script>
import { INSERT, QUERYED, DELETE } from "@/services/dao.js";
import { integralAddOne } from "@/js/common/index.js";
export default {
  data() {
    return {
      expertlist: [],
      show: true,
      show_war: false,
    };
  },
  created() {
    this.expert();
    console.log(this.Collectionlist);
  },
  methods: {
    async expert() {
      let res = await QUERYED(
        "post",
        "",
        "ZYUser{ id   username    circleurl   works    }"
      );

      if (
        this.$store.state.userinfo.id < 0 ||
        this.$store.state.userinfo.id == "" ||
        this.$store.state.userinfo.id == null ||
        this.$store.state.userinfo.id == "undefined"
      ) {
        this.expertlist = res.data.ZYUser;
        for (var j = 0; j < this.expertlist.length; j++) {
          let info = await QUERYED(
            "post",
            "",
            'ZYDocumentByTime( where: {user: {_eq: "' +
              res.data.ZYUser[j].id +
              '"}},limit: ' +
              10 +
              ", offset: " +
              0 +
              ") { id   name  read download   score  score  Price Time  }"
          );
          this.expertlist[j].works = info.data.ZYDocumentByTime.length;
          this.expertlist[j].circleurl = this.api.LoginURL.concat(
            this.expertlist[j].circleurl
          );
        }
      } else {
        for (let i = 0; i < res.data.ZYUser.length; i++) {
          let info = await QUERYED(
            "post",
            "",
            'ZYDocumentByTime( where: {user: {_eq: "' +
              res.data.ZYUser[i].id +
              '"}},limit: ' +
              10 +
              ", offset: " +
              0 +
              ") { id   name  read download   score  score  Price Time  }"
          );
          res.data.ZYUser[i].works = info.data.ZYDocumentByTime.length;
          let cm = await QUERYED(
            "post",
            "",
            'ZYConcernMan( where: {concernMan: {_eq: "' +
              this.$store.state.userinfo.id +
              '"},beConcernMan: {_eq: "' +
              res.data.ZYUser[i].id +
              '"}},limit: ' +
              10 +
              ", offset: " +
              0 +
              ") { id    }"
          );
          if (cm.data.ZYConcernMan.length > 0) {
            res.data.ZYUser[i].show = false;
            res.data.ZYUser[i].show_war = true;
          } else {
            res.data.ZYUser[i].show = true;
            res.data.ZYUser[i].show_war = false;
          }
        }
        this.expertlist = res.data.ZYUser;
        for (var i = 0; i < this.expertlist.length; i++) {
          this.expertlist[i].circleurl = this.api.LoginURL.concat(
            this.expertlist[i].circleurl
          );
        }
        this.expertlist.sort(function(a, b) {
          return b.works - a.works;
        });
      }
    },
    async subscribe(item, index) {
      let date = new Date();
      let info = await INSERT(
        "POST",
        "",
        'insert_ConcernMan(objects: {concernMan: "' +
          this.$store.state.userinfo.id +
          '", beConcernMan: "' +
          item.id +
          '", time: "' +
          date +
          '", type: "2"}) {    affected_rows  }'
      );
      if (info.data.insert_ConcernMan.affected_rows > 0) {
        this.$message({
          message: "已关注",
          type: "warning",
        });
        console.log(this.expertlist[index].show);
        this.expertlist[index].show = false;
        this.expertlist[index].show_war = true;
        console.log(this.expertlist[index].show);
      }
    },
    async subscribe_war(item, index) {
      let info = await DELETE(
        "POST",
        "",
        ' delete_ConcernMan(where: {beConcernMan: {_eq: "' +
          item.id +
          '"}, concernMan: {_eq:"' +
          this.$store.state.userinfo.id +
          '"},type: {_eq:"' +
          2 +
          '"}}) {    affected_rows  }'
      );
      if (info.data.delete_ConcernMan.affected_rows > 0) {
        this.$message({
          message: "已取关",
          type: "warning",
          duration: "1000",
        });
        this.expertlist[index].show = true;
        this.expertlist[index].show_war = false;
      }
    },
    repot(item) {
      let user = this.$store.state.userinfo.id;
      if(user){
      integralAddOne(user); ///积分加1
      }
      this.$router.push({
        path: "/Reexpert",
        query: {
          id: item.id,
        },
      });
    },
  },
};
</script>

<style scoped>
.plcont {
  overflow: hidden;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 40px;
}
.plcont img {
  width: 68px;
  height: 68px;
  border-radius: 999px;
  float: left;
}
.centr {
  float: left;
  margin-top: 25px;
  width: 50px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 10px;
}
.plcont button {
  float: right;
  margin-right: 15px;
  margin-top: 25px;
}
.el-aside .aside_t_b {
  padding: 10px;
  margin-top: 10px;
  height: 600px;
  background-color: #ffffff;
}
</style>
